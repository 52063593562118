<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="1080px">
        <v-card>
          <v-card-title>
            <span class="headline">Nueva Factura</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" md="8">
                    <invoice-field
                      v-model="invoiceId"
                      :reload="load"
                      :providerId="provider"
                      :businessId="business"
                      :type="paytype"
                      :exclude="excludeInvoice"
                    ></invoice-field>
                  </v-col>
                  <v-col>
                    <v-btn fab color="indigo" @click="newInvoice()">
                      <v-icon color="white">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider></v-divider>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="text-left">Factura</th>
                    <th class="text-left">Proveedor</th>
                    <th class="text-left">Retención ISR</th>
                    <th class="text-left">Retención IVA</th>
                    <th class="text-left">Exento</th>
                    <th class="text-left">Pendiente</th>
                    <th class="text-left">Descripción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ invoiceId.serie }}{{ invoiceId.invoice_number }}</td>
                    <td>{{ invoiceId.provider.name }}</td>
                    <td>{{ invoiceId.isr }}</td>
                    <td>{{ invoiceId.iva }}</td>
                    <td>{{ invoiceId.exempt }}</td>
                    <td>
                      {{ calculateTax(invoiceId.isr, invoiceId.iva, invoiceId.balance) }}
                    </td>
                    <td>{{ invoiceId.description }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close()"> Cerrar </v-btn>
            <v-btn color="blue darken-1" text @click="save()"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <new-invoice
      :dialog="invoiceDialog"
      :provider="provider"
      :business="business"
      :credit="paytype"
      :disabledP="disableProv"
      @close="invoiceDialog = false"
      @saved="reload()"
    ></new-invoice>
  </v-container>
</template>
<script>
import request from "@/services/requests";
export default {
  components: {
    invoiceField: () => import("@/components/fields/InvoiceField"),
    newInvoice: () => import("@/components/accounting/invoice/CreateInvoice"),
  },
  props: {
    order: [String, Number],
    dialog: { type: Boolean, required: true },
    provider: { type: Number, required: true },
    business: { type: Number, required: true },
    paytype: { type: String },
    update: { type: Boolean, default: true, required: false },
  },
  data: () => ({
    disableProv: true,
    valid: false,
    load: true,
    excludeInvoice: [],
    invoiceId: {
      provider: {
        id: 0,
        name: "",
      },
    },
    invoiceDialog: false,
    data: {},
  }),
  methods: {
    close() {
      this.$emit("close");
      this.$refs.form.reset();
      this.invoiceId = {
        provider: {
          id: 0,
          name: "",
        },
      };
    },
    save() {
      if (this.update) {
        request
          .patch("/api/detail-payment/" + this.order + "/", {
            document: this.invoiceId.serie + "-" + this.invoiceId.invoice_number,
            invoice: this.invoiceId.id,
          })
          .then((resp) => {
            if (resp.status == 200) {
              this.close();
              this.$emit("saved");
              this.reload();
            } else {
              this.$toasted.global.info({
                message: resp.data.detail,
              });
            }
          });
      } else {
        request
          .post("/api/detail-payment/", {
            payment_id: this.order,
            document: this.invoiceId.serie + "-" + this.invoiceId.invoice_number,
            invoice: this.invoiceId.id,
          })
          .then((resp) => {
            if (resp.status == 201) {
              this.close();
              this.$emit("saved");
              this.reload();
            }
          });
      }
    },
    newInvoice() {
      if (this.paytype == "CJCH") {
        this.disableProv = false;
      }
      this.invoiceDialog = true;
    },
    calculateTax(isr, iva, amount) {
      let tax = Number(isr) + Number(iva);
      let result = Number(amount) - Number(tax);
      result = isNaN(result) ? 0 : result;
      return Number(result).toFixed(2);
    },
    reload() {
      this.load = this.load == true ? false : true;
    },
  },
};
</script>
